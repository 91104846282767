<template>
  <b-row>
    <b-col cols="12">
      <h2>Usuarios Administradores</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80" />

    <div class="col-12 mt-16">
      <datatable
        :items="items"
        :fields="computedFields"
      >
        <template slot="filters">
          <b-col
            v-if="user.is_super_admin"
            cols="3"
            class="my-3"
          >
            <b-button
              v-b-modal.modal-add-departament-admin
              variant="primary"
              class="btn-ghost"
            >
              Añadir nuevo administrador
            </b-button>
          </b-col>
        </template>

        <template
          slot="name"
          slot-scope="{ item }"
        >
          <span>{{ item.is_admin ? 'Administrador' : 'Conserje' }}</span>
        </template>

        <template
          slot="actions"
          slot-scope="{ item }"
        >
          <b-row class="actions-icons">
            <span
              :id="'_show_button_' + item.uuid"
              title="ver"
              @click="showUser(item.user.uuid)"
            >
              <i class="iconly-Light-Show" />
            </span>
            <span
              :id="'_show_editar' + item.uuid"
              title="editar"
              @click="editUser(item.user.uuid)"
            >
              <i class="iconly-Light-Edit mr-10" />
            </span>
            <span
              :id="'_show_eliminar' + item.uuid"
              title="eliminar"
              @click="showDeleteConfirmation(item.uuid)"
            >
              <i class="iconly-Light-Delete mr-10" />
            </span>
          </b-row>

          <b-tooltip
            :target="'_show_button_' + item.uuid"
            triggers="hover"
          >
            Ver
          </b-tooltip>

          <b-tooltip
            :target="'_show_editar' + item.uuid"
            triggers="hover"
          >
            Editar
          </b-tooltip>

          <b-tooltip
            :target="'_show_eliminar' + item.uuid"
            triggers="hover"
          >
            Eliminar
          </b-tooltip>
        </template>

        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          />
        </template>
      </datatable>
    </div>

    <!-- search -->
    <b-modal
      id="modal-add-departament-admin"
      size="xl"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-departament-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Añadir nuevo administrador
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <form inline>
        <b-row>
          <b-col cols="col-12 mt-16">
            <b-col cols="12">
              <b-input-group class="mt-3">
                <b-form-input
                  v-model="searchUser.email"
                  placeholder="escriba un correo"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    class="btn btn-ghost btn-primary"
                    @click.prevent="SearchUserEmail"
                  >buscar</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-col>

          <b-col cols="6 pl-12" />
        </b-row>
      </form>
    </b-modal>

    <!-- show -->
    <b-modal
      id="modal-show-admin"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Ver admin
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre completo">
              <b-form-input
                v-model="showAdmin.fullName"
                type="text"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="Email">
              <b-form-input
                v-model="showAdmin.email"
                type="email"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="Teléfono">
              <b-form-input
                v-model="showAdmin.phone"
                type="text"
                maxlength="14"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="RUT">
              <b-form-input
                v-model="showAdmin.dni"
                type="text"
                maxlength="14"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="Fecha de nacimiento">
              <b-form-input
                v-model="showAdmin.birth"
                type="text"
                maxlength="14"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8 mb-8"
          >
            <b-form-group label="Address">
              <b-form-textarea
                v-model="showAdmin.address"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-show-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- Modal de confirmación de eliminación -->
    <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Confirmar eliminación
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar este administrador?
          </p>
        </b-col>

        <b-col
          cols="6"
          class="pr-2"
        >
          <b-button
            variant="danger"
            block
            @click="confirmDelete"
          >
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col
          cols="6"
          class="pl-2"
        >
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- edit -->
    <b-modal
      id="modal-edit-admin"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Editar admin
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre completo">
              <b-form-input
                v-model="showAdmin.fullName"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="Email">
              <b-form-input
                v-model="showAdmin.email"
                type="email"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="Teléfono">
              <b-form-input
                v-model="showAdmin.phone"
                type="text"
                maxlength="14"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="RUT">
              <imask-input
                v-imask="dniMask"
                class="form-control"
                :value="showAdmin.dni"
                :unmask="true"
                placeholder="XX.XXX.XXX-Y"
                @complete="onCompleteDni"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="Fecha de nacimiento">
              <imask-input
                v-imask="mask"
                class="form-control"
                :value="showAdmin.birth"
                :unmask="true"
                placeholder="día/mes/año"
                @complete="onCompleteDob"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="Teléfono">
              <imask-input
                v-imask="phoneMask"
                class="form-control"
                :value="showAdmin.phone"
                :unmask="true"
                placeholder="+56 XXX XXX XXX"
                @complete="onCompletePhone"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8 mb-8"
          >
            <b-form-group label="Address">
              <b-form-textarea v-model="showAdmin.address" />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8 mb-8"
          >
            <b-form-group label="Rol">
              <b-form-select
                v-model="showAdmin.is_admin"
                :options="[
                  { value: true, text: 'Administrador' },
                  { value: false, text: 'Conserje' },
                ]"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button
              variant="primary"
              block
              @click="EditAdministrador"
            >
              Editar
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- create -->
    <b-modal
      id="modal-create-admin"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Crear nuevo administrador
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Género">
              <b-form-select
                v-model="createAdmin.gender"
                :options="[
                  { value: 'Male', text: 'Hombre' },
                  { value: 'Female', text: 'Mujer' },
                  { value: 'Other', text: 'Otros' },
                ]"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Primer nombre">
              <b-form-input
                v-model="createAdmin.name"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Segundo nombre">
              <b-form-input
                v-model="createAdmin.name2"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Primer apellido">
              <b-form-input
                v-model="createAdmin.last_name"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Segundo apellido">
              <b-form-input
                v-model="createAdmin.last_name2"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Correo electrónico">
              <b-form-input
                v-model="findSearchUser"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Clave">
              <b-form-input
                v-model="createAdmin.password"
                type="password"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Teléfono">
              <imask-input
                v-imask="phoneMask"
                class="form-control"
                :value="createAdmin.phone"
                :unmask="true"
                placeholder="+56 XXX XXX XXX"
                @complete="onCompletePhone"
              />
              <!-- <b-form-input v-model="createAdmin.phone" type="text"></b-form-input> -->
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Tipo de Documento">
              <b-form-select
                v-model="createAdmin.dni_type_uuid"
                :options="DniType"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="
              createAdmin.dni_type_uuid == 4 || createAdmin.dni_type_uuid == 3
            "
            cols="12"
          >
            <b-form-group label="RUT">
              <imask-input
                v-imask="dniMask"
                class="form-control"
                :value="createAdmin.dni"
                :unmask="true"
                placeholder="00.000.000-00"
                @complete="onCompleteDni"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="
              createAdmin.dni_type_uuid != 4 && createAdmin.dni_type_uuid != 3
            "
            cols="12"
          >
            <b-form-group label="Documento">
              <b-form-input
                v-model="createAdmin.dni"
                type="text"
              />
            </b-form-group>
          </b-col>

          <!-- <b-col cols="12">
            <b-form-group label="Rut">
              <imask-input
                class="form-control"
                :value="createAdmin.dni"
                v-imask="dniMask"
                :unmask="true"
                @complete="onCompleteDni"
                placeholder='XX.XXX.XXX-Y'
              />

            </b-form-group>
          </b-col> -->
          <!-- @complete="onComplete" -->
          <b-col cols="12">
            <b-form-group label="Fecha de nacimiento">
              <imask-input
                v-imask="mask"
                class="form-control"
                :value="createAdmin.birth"
                :unmask="true"
                placeholder="día/mes/año"
                @complete="onCompleteDob"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Dirección">
              <b-form-input
                v-model="createAdmin.address"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Rol">
              <b-form-select
                v-model="createAdmin.is_admin"
                :options="[
                  { value: true, text: 'Administrador' },
                  { value: false, text: 'Conserje' },
                ]"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <span class="hp-caption text-black-80 hp-text-color-dark-30 pr-8">
              Activo
            </span>

            <b-form-checkbox
              v-model="createAdmin.active"
              switch
              size="lg"
              value="true"
            />
          </b-col>

          <b-col cols="6 pr-12">
            <b-button
              variant="primary"
              block
              @click="CreateNewAdmin"
            >
              Crear
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-create-admin')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
} from 'bootstrap-vue'

import datatable from '@/layouts/components/datatable/Datatable.vue'
import sweet from '@/logic/functions/sweetAlert'
import users from '@/logic/functions/users'
import buildingsAdmin from '@/logic/functions/buildingsAdmin'
import dni from '@/logic/functions/dni'
import Locations from '@/logic/functions/Locations'
import { DateTime } from 'luxon'
import { IMaskComponent, IMaskDirective, useIMask } from 'vue-imask'
import validate_dni from '@/logic/functions/users/validate_dni'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, // this
    BPagination,
    datatable,
    'imask-input': IMaskComponent,
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('home_app_user')).user,
      fields: [
        {
          key: 'user.name',
          label: 'Nombre',
          formatter: (value, key, item) => {
            const { name } = item.user
            const name_two = item.user.name2 != null ? item.user.name2 : ''
            const last_name = item.user.last_name != null ? item.user.last_name : ''
            const last_name_two = item.user.last_name2 != null ? item.user.last_name2 : ''

            return (
              `${name} ${last_name} ${name_two} ${last_name_two}`
            )
          },
        },
        { key: 'user.dni', label: 'Rut' },
        { key: 'name', label: 'Rol' },
        { key: 'user.phone', label: 'Teléfono' },
        { key: 'actions', label: 'Acciones' },
      ],
      Actions: [],
      items: [],
      loadNewUsers: [],
      codeText: '',
      codeActive: false,
      codeActiveClass: false,
      addNewAdmin: {},
      searchUser: {},
      findSearchUser: null,
      showAdmin: {},
      createAdmin: {
        dni_type_uuid: 3,
      },
      DniType: [],
      cityType: [],
      mask: {
        mask: '00/00/0000',
        lazy: false,
      },
      phoneMask: {
        mask: '{+56} 000 000 000',
        lazy: false,
      },
      dniMask: {
        mask: '00.000.000-{[*]}',
        lazy: false,
      },
      dniIsValid: false,
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
    }
  },
  computed: {
    computedFields() {
      return this.fields.filter(field => {
        if (field.key === 'actions' && !this.user.is_super_admin) return false

        return true
      })
    },
  },
  beforeMount() {},
  mounted() {
    this.listDniType()
    this.listCityType()
    this.ListAdminsBuildings()
  },
  methods: {
    onCompleteDob(e) {
      this.showAdmin.birth = e.detail._value
      this.createAdmin.birth = e.detail._value
    },
    onCompleteDni(e) {
      const dni = e.detail._value.replace(/\./g, '')
      if (validate_dni.validaRut(dni)) {
        this.dniIsValid = true
        this.showAdmin.dni = e.detail._value
        this.createAdmin.dni = e.detail._value
        e.target.style.border = 'none'
      } else {
        this.dniIsValid = false
        e.target.style.border = '3px solid #FF0000'
      }
    },
    onCompletePhone(e) {
      this.showAdmin.phone = e.detail._value
      this.createAdmin.phone = e.detail._value
    },
    showUser(dni) {
      this.showAdmin = this.items.find(item => item.user.uuid == dni)

      this.showAdmin = this.showAdmin.user

      this.showAdmin.birth = DateTime.fromISO(this.showAdmin.birth).toFormat(
        'dd/MM/yyyy',
      )

      this.showAdmin.fullName = `${this.showAdmin.name
      } ${
        this.showAdmin.last_name
      } ${
        this.showAdmin.name2
      } ${
        this.showAdmin.last_name2}`

      this.$bvModal.show('modal-show-admin')
    },
    editUser(dni) {
      this.showAdmin = this.items.find(item => item.user.uuid == dni)

      this.showAdmin = this.showAdmin.user
      this.showAdmin.birth = DateTime.fromISO(this.showAdmin.birth).toFormat(
        'dd/MM/yyyy',
      )
      this.showAdmin.fullName = `${this.showAdmin.name
      } ${
        this.showAdmin.last_name
      } ${
        this.showAdmin.name2
      } ${
        this.showAdmin.last_name2}`

      this.$bvModal.show('modal-edit-admin')
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteUser(this.itemToDelete)
        this.$bvModal.hide('modal-delete-confirmation')
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid
      this.$bvModal.show('modal-delete-confirmation')
    },
    deleteUser(uuid) {
      const { items } = this
      buildingsAdmin
        .deletAdmin(uuid.uuid)
        .then(response => {
          const index = items.findIndex(
            item => item.user.uuid === uuid.user.uuid,
          )
          if (~index) {
            items.splice(index, 1)
          }
          sweet.ToastMsg('', 'success', 'Administrador eliminado')
        })
        .catch(error => {
          console.log(error)
        })
    },
    async EditAdministrador() {
      this.dniIsValid = this.showAdmin.dni_type_uuid == 3 || this.showAdmin.dni_type_uuid == 4
        ? this.dniIsValid
        : true
      if (this.dniIsValid) {
        const UserCreateData = {
          active:
            this.showAdmin.active !== null ? this.showAdmin.active : false, // this.showAdmin.active,
          address: this.showAdmin.address,
          birth: this.showAdmin.birth,
          dni: this.showAdmin.dni,
          dni_type_uuid: `${this.showAdmin.dni_type_uuid}`,
          email: this.findSearchUser, // this.showAdmin.email,
          gender: this.showAdmin.gender,
          last_name: this.showAdmin.last_name,
          last_name2: this.showAdmin.last_name2,
          name: this.showAdmin.name,
          name2: this.showAdmin.name2,
          nationality_id: 'CL',
          password: this.showAdmin.password,
          phone: this.showAdmin.phone,
          is_admin: this.showAdmin.is_admin,
        }

        await users
          .update_administrador(this.showAdmin.uuid, UserCreateData)
          .then(response => {
            if (response.data) {
              buildingsAdmin
                .add_admin({
                  user_uuid: response.data.uuid,
                  active: true,
                  is_admin: UserCreateData.is_admin,
                })
                .then(response => {
                  this.ListAdminsBuildings()
                  this.$bvModal.hide('modal-create-admin')
                  sweet.ToastMsg('', 'success', 'admin editado')
                })
                .catch(error => {
                  sweet.ToastMsg('', 'error', 'admin no editado')
                })
            }
          })
          .catch(error => {})

        this.$bvModal.hide('modal-edit-admin')
        sweet.ToastMsg('', 'success', 'Administrador actualizado')
      }
    },
    addNewAdminToDepartament(dni) {
      this.items.push(
        this.loadNewUsers.find(item => item.dni == dni),
      )
      this.$bvModal.hide('modal-add-departament-admin')
    },
    listCityType() {
      Locations.listCity()
        .then(response => {
          Object.entries(response.data.data).forEach(entry => {
            const [key, value] = entry
            this.cityType.push({ value: value.uuid, text: value.name })
          })
        })
        .catch(error => {})
    },
    listDniType() {
      dni
        .list()
        .then(response => {
          Object.entries(response.data.data).forEach(entry => {
            const [key, value] = entry
            this.DniType.push({ value: value.uuid, text: value.name })
          })
        })
        .catch(error => {})
    },
    SearchUserEmail() {
      const adminUsers = this.items

      users
        .searchuseremail(this.searchUser.email)
        .then(response => {
          if (response.data.uuid) {
            this.$bvModal.hide('modal-add-departament-admin')

            const responseData = response.data

            buildingsAdmin
              .add_admin({
                user_uuid: response.data.uuid,
                active: true,
              })
              .then(response => {
                this.ListAdminsBuildings()
                sweet.ToastMsg('', 'success', 'admin asignado')
              })
              .catch(error => {
                sweet.ToastMsg('', 'error', 'admin user no asignado')
              })
          }
        })
        .catch(error => {
          this.createAdmin = {
            dni_type_uuid: 3,
          }
          this.$bvModal.hide('modal-add-departament-admin')
          this.$bvModal.show('modal-create-admin')

          // sweet.ToastMsg('','error','no se pueden cargar los departamentos');
        })
    },
    async CreateNewAdmin() {
      this.dniIsValid = this.createAdmin.dni_type_uuid == 3
        || this.createAdmin.dni_type_uuid == 4
        ? this.dniIsValid
        : true
      if (this.dniIsValid) {
        const UserCreateData = {
          active:
            this.createAdmin.active !== null ? this.createAdmin.active : false, // this.createAdmin.active,
          address: this.createAdmin.address,
          birth: this.createAdmin.birth,
          dni: this.createAdmin.dni,
          dni_type_uuid: `${this.createAdmin.dni_type_uuid}`,
          email: this.findSearchUser, // this.createAdmin.email,
          gender: this.createAdmin.gender,
          last_name: this.createAdmin.last_name,
          last_name2: this.createAdmin.last_name2,
          name: this.createAdmin.name,
          name2: this.createAdmin.name2,
          nationality_id: 'CL',
          password: this.createAdmin.password,
          phone: this.createAdmin.phone,
          is_admin: this.createAdmin.is_admin,
        }

        await users
          .create_user(UserCreateData)
          .then(response => {
            if (response.data) {
              buildingsAdmin
                .add_admin({
                  user_uuid: response.data.uuid,
                  active: true,
                  is_admin: UserCreateData.is_admin,
                })
                .then(response => {
                  this.ListAdminsBuildings()
                  this.$bvModal.hide('modal-create-admin')
                  sweet.ToastMsg('', 'success', 'admin asignado')
                })
                .catch(error => {
                  sweet.ToastMsg('', 'error', 'admin no asignado')
                })
            }
          })
          .catch(error => {})
      }
    },
    async ListAdminsBuildings(page = 0) {
      await buildingsAdmin
        .list({
          params: {
            limit: 5,
            page,
          },
        })
        .then(response => {
          if (response.data) {
            const LogUserUuid = JSON.parse(localStorage.getItem('home_app_user'))
              .user.uuid

            const show = response.data.data.filter(entry => (
              entry.deleted_at === null && entry.user.uuid != LogUserUuid
            ))
            console.log(show)
            this.items = show
          }
        })
        .catch(error => {})
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1

      this.ListAdminsBuildings(page)
    },
  },
}
</script>
