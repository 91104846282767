<template>
  <b-row>
    <b-col
      cols="12"
      md="8"
    >
      <h2>Información del edificio</h2>
      <p class="hp-p1-body mb-0" />
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80" />

    <b-col cols="12">
      <b-row
        align-v="center"
        align-h="between"
      >
        <b-col
          cols="12"
          md="6"
        >
          <h3>información</h3>
        </b-col>

        <b-col
          v-if="user.is_super_admin"
          cols="12"
          md="6"
          class="hp-profile-action-btn text-right"
        >
          <b-button
            v-b-modal.modal-edit-building
            variant="primary"
            class="btn-ghost"
          >
            Editar
          </b-button>
        </b-col>

        <b-col
          cols="12"
          class="hp-profile-content-list mt-8 pb-0 pb-sm-120"
        >
          <ul>
            <li>
              <span class="hp-p1-body">Nombre</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ buildinginfo.name }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Tipo</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ buildininfo_building_type_name }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Comuna</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ this.buildininfo_city_name }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Dirección</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ buildinginfo.address }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">RUT</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ buildinginfo.dni }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Razón social</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ buildinginfo.razon_social }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Edificio activo ?</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ !!buildinginfo.active == true ? 'si' : 'no' }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Sobre nosotros</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0 "
              >
                {{ buildinginfo.about_us }}
              </span>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80" />

    <b-modal
      id="modal-edit-building"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Editar edificio
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <form>
        <b-row>

          <b-col cols="12">
            <b-form-group label="Tipo de edificio">
              <b-form-select
                v-model="buildininfo_building_type_uuid"
                :options="buildingtype"
                @change="changeType"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input
                v-model="buildinginfo.name"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Locación">
              <b-form-select
                v-model="buildininfo_city_uuid"
                :options="locationtype"
                @change="changeCity"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="RUT">

              <imask-input
                v-imask="mask"
                class="form-control"
                :value="buildinginfo.dni"
                :unmask="true"
                placeholder="00.000.000-00"
                @complete="onComplete"
              />
              <!-- <b-form-input v-model="buildinginfo.dni" type="text"></b-form-input> -->
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Razón social">
              <b-form-input
                v-model="buildinginfo.razon_social"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Sobre nosotros">
              <b-form-textarea
                v-model="buildinginfo.about_us"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Dirección">
              <b-form-input
                v-model="buildinginfo.address"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <span class="hp-caption text-black-80 hp-text-color-dark-30 pr-8">
              Activo
            </span>

            <b-form-checkbox
              v-model="buildinginfo.active"
              switch
              size="lg"
              value="true"
            />
          </b-col>

          <b-col cols="6 pr-12">
            <b-button
              variant="primary"
              block
              @click="edit"
            >
              Editar
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-building')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCard,
  BFormCheckbox,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'
import sweet from '@/logic/functions/sweetAlert'

import buildingEndpoint from '@/logic/functions/buildings'
import buildingType from '@/logic/functions/buildingsType'
import Locations from '@/logic/functions/Locations'
import { IMaskComponent, IMaskDirective } from 'vue-imask'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption,
    'imask-input': IMaskComponent,

  },
  directives: {
    imask: IMaskDirective,
  },
  props: {
    building: {
      default: {},
      type: Object,
    },
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('home_app_user')).user,
      buildinginfo: this.building,
      buildininfo_building_type_uuid: '',
      buildininfo_building_type_name: '',
      buildininfo_city_uuid: '',
      buildininfo_city_name: '',
      buildingtype: [],
      locationtype: [],
      mask: {
        mask: '00.000.000-{[*]}',

        // /^(\d{1,2})(\d{3})(\d{3})(\w{1})$/,
        lazy: false,
      },
    }
  },
  watch: {
    building() {
      this.buildinginfo = this.building
      this.buildininfo_building_type_uuid = this.building.building_type_uuid.uuid
      this.buildininfo_building_type_name = this.building.building_type_uuid.name
      this.buildininfo_city_uuid = this.building.city_uuid.uuid
      this.buildininfo_city_name = this.building.city_uuid.name
    },
  },
  mounted() {
    this.ListBuildingType()
    this.ListLocations()
  },
  methods: {
    onComplete(e) {
      this.buildinginfo.dni = e.detail._value
    },
    changeCity(data) {
      const city = this.locationtype.find(item => item.value == data)

      this.buildininfo_city_uuid = city.value
      this.buildininfo_city_name = city.text
    },
    changeType(data) {
      const type = this.locationtype.find(item => item.value == data)

      this.buildininfo_building_type_uuid = type.value
      this.buildininfo_building_type_name = type.text
    },
    edit() {
      const updateData = this.buildinginfo

      updateData.building_type_uuid = this.buildininfo_building_type_uuid
      updateData.city_uuid = this.buildininfo_city_uuid

      buildingEndpoint.update(this.$route.params.id, updateData)
        .then(response => {
          this.$bvModal.hide('modal-edit-building')
          sweet.ToastMsg('', 'success', 'departamento actualizado')
        })
        .catch(error => {
          console.log(error)
          sweet.ToastMsg('', 'error', 'no se pudo editar el edificio')
        })
    },
    ListBuildingType() {
      buildingType.list()
        .then(response => {
          const { data } = response.data

          Object.entries(data).forEach(entry => {
            const [key, value] = entry
            this.buildingtype.push({ value: value.uuid, text: value.name })
          })
        })
        .catch(error => {
          console.log(error)
          sweet.ToastMsg('', 'error', 'no se pudo crear el edificio')
        })
    },
    ListLocations() {
      Locations.list()
        .then(response => {
          const { data } = response.data

          Object.entries(data).forEach(entry => {
            const [key, value] = entry
            this.locationtype.push({ value: value.uuid, text: value.name })
          })
        })
        .catch(error => {
          console.log(error)
          sweet.ToastMsg('', 'error', 'no se pudo crear el edificio')
        })
    },
  },
}
</script>
