<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-32"
    >
      <b-row
        align-h="between"
        class="mt-n32"
      >
        <b-col class="hp-flex-none w-auto mt-32">
          <breadcrumb />
        </b-col>

      </b-row>
    </b-col>

    <b-col
      cols="12"
      class="mb-32"
    >
      <b-row
        class="hp-profile-mobile-menu-btn bg-black-0 hp-bg-color-dark-100 rounded py-12 px-8 px-sm-12 mb-16 mx-0"
      >
        <div class="d-inline-block">
          <b-button
            v-b-toggle.sidebar-profile
            variant="none"
            class="border-0 btn-icon-only"
          >
            <i
              class="ri-menu-fill hp-text-color-black-80 hp-text-color-dark-30 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>
      </b-row>

      <b-row
        class="bg-black-0 hp-bg-color-dark-100 rounded pr-16 pr-sm-32 mx-0"
      >
        <sidebar :building="building" />

        <b-col class="pl-16 pl-sm-32 p--24 py-sm-32 overflow-hidden">
          <Informations
            v-if="$route.name == 'get-building'"
            :building="building"
          />

          <Departaments
            v-if="$route.name == 'get-building-departments'"
            :building="building"
          />

          <Admin
            v-if="$route.name == 'get-building-admins'"
            :building="building"
          />
        </b-col>
      </b-row>

      <b-sidebar
        id="sidebar-profile"
        no-header
        shadow
        bg-variant="white"
        body-class="pt-16 px-0"
        class="hp-profile-mobile-menu"
        backdrop
      >
        <template #default="{ hide }">
          <div
            class="px-24 mb-32 d-flex align-items-center justify-content-between"
          >
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
            >
              <template #button-content>
                <div class="d-inline-block">
                  <b-button
                    variant="none"
                    class="btn-icon-only bg-transparent border-0 hp-hover-bg-black-10 hp-hover-bg-dark-100 hp-transition"
                  >
                    <i
                      class="ri-more-2-line text-black-100 hp-text-color-dark-30 lh-1"
                      style="font-size: 24px"
                    />
                  </b-button>
                </div>
              </template>
              <b-dropdown-item href="#">
                Change Avatar
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              variant="none"
              class="btn-icon-only bg-transparent border-0 hp-hover-bg-black-10 hp-hover-bg-dark-100 hp-transition"
              @click="hide"
            >
              <i
                class="ri-close-fill text-black-80 lh-1"
                style="font-size: 24px"
              />
            </b-button>
          </div>

          <sidebar :building="building" />
        </template>
      </b-sidebar>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import Breadcrumb from '@/layouts/components/content/breadcrumb/Breadcrumb.vue'
import ActionButton from '@/layouts/components/content/action-button/ActionButton.vue'
import building from '@/logic/functions/buildings'
import buildingType from '@/logic/functions/buildingsType'
import Locations from '@/logic/functions/Locations'
import users from '@/logic/functions/users'
import Sidebar from './Sidebar.vue'

import Admin from './Admin.vue'
import Departaments from './Department.vue'
import Informations from './Information.vue'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    Breadcrumb,
    ActionButton,
    Sidebar,
    Admin,
    Departaments,
    Informations,

  },
  data() {
    return {
      routeName: '',
      building: {},
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  watch: {
    '$route.params.id': {
      handler(value) {
        this.getBuildingInfo()
      },
    },
  },
  beforeMount() {

  },
  mounted() {
    this.getBuildingInfo()
  },
  methods: {
    async getBuildingInfo() {
      await users.my_buildings({
        params: {
          building_uuid: this.$route.params.id,
        },
      })
        .then(response => {
          this.building = response.data
        }).catch(error => {
          console.log(error)
          sweet.ToastMsg('', 'error', 'no se pudo obtener los edificios')
        })
    },
  },
}
</script>
