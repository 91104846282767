<template>
  <b-row>
    <b-col cols="12">
      <h2>Departamentos</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80" />

    <div class="col-12 mt-16">
      <datatable
        :items="items"
        :fields="computedFields"
        @changePerPage="perPageChanged"
      >
        <template slot="filters">
          <b-col
            v-if="user.is_super_admin"
            cols="3"
            class="my-3"
          >
            <b-button
              v-b-modal.modal-create-departament
              variant="primary"
              class="btn-ghost"
            >
              Añadir nuevo departamentos
            </b-button>
          </b-col>
        </template>
        <template slot="filters">
          <b-col
            v-if="user.is_super_admin"
            cols="2"
            class="my-3"
          >
            <b-button
              v-b-modal.modal-add-document-admin
              variant="primary"
              class="btn-ghost"
            >
              carga masiva de inquilinos
            </b-button>
          </b-col>
        </template>

        <template
          slot="actions"
          slot-scope="{ item }"
        >
          <b-row
            class="actions-icons"
            style="display: flex; justify-content: center"
          >
            <span
              :id="'_show_button_' + item.uuid"
              title="ver"
              @click="show(item.uuid)"
            >
              <i class="iconly-Light-Show" />
            </span>
            <span
              :id="'_show_editar' + item.uuid"
              title="editar"
              @click="edit(item.uuid)"
            >
              <i class="iconly-Light-Edit mr-10" />
            </span>
            <span
              :id="'_show_eliminar' + item.uuid"
              title="eliminar"
              @click="delet(item.uuid)"
            >
              <i class="iconly-Light-Delete mr-10" />
            </span>
          </b-row>

          <b-tooltip
            :target="'_show_button_' + item.uuid"
            triggers="hover"
          >
            Ver
          </b-tooltip>

          <b-tooltip
            :target="'_show_editar' + item.uuid"
            triggers="hover"
          >
            Editar
          </b-tooltip>

          <b-tooltip
            :target="'_show_eliminar' + item.uuid"
            triggers="hover"
          >
            Eliminar
          </b-tooltip>
        </template>
        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          />
        </template>
      </datatable>
    </div>

    <b-modal
      id="modal-create-departament"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Añadir nuevo departamento
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Número del departamento">
              <b-form-input
                v-model="createDepartament.nro"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button
              variant="primary"
              block
              @click="createDepartaments"
            >
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-create-departament')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- create document -->
    <b-modal
      id="modal-add-document-admin"
      size="xl"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-document-admin"
      modal-class="hp-profile-modal-1"
      @hide="modalClose"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Añadir inquilinos
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Cargar Archivo">
              <b-form-file
                ref="file-input"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style="margin-bottom: 20px"
                @change="uploadFile"
              />

              <!-- <input type="file" @change="uploadFile" ref="file">
              <button @click="submitFile">Subir imagen</button> -->
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button
              variant="primary"
              block
              @click="submitFile"
            >
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="downloadTemplate()"
            >
              Descargar ejemplo
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <b-modal
      id="modal-edit-departament"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Editar departamento
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Numero del departamento">
              <b-form-input
                v-model="editDepartament.nro"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button
              variant="primary"
              block
              @click="editDepartaments"
            >
              Editar
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-departament')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormFile,
  BFormInput,
  BPagination,
} from 'bootstrap-vue'
import datatable from '@/layouts/components/datatable/Datatable.vue'
import sweet from '@/logic/functions/sweetAlert'
import departaments from '@/logic/functions/departaments'
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    BButton,
    BRow,
    BFormFile,
    BCol,
    BFormGroup,
    BFormInput,
    datatable,
    BPagination,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('home_app_user')).user,
      fields: [
        {
          key: 'nro', label: 'Número', sortable: true,
        },
        {
          key: 'principal_user', label: 'Inquilino Principal', sortable: true,
        },
        {
          key: 'actions', label: 'Acciones', thClass: 'custom-actions',
        },
      ],
      items: [],
      codeText: '',
      documentCreate: {},
      codeActive: false,
      codeActiveClass: false,
      departamentUuid: this.$route.params.id,
      createDepartament: {},
      editDepartament: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
    }
  },
  computed: {
    computedFields() {
      return this.fields.filter(field => {
        if (field.key === 'actions' && !this.user.is_super_admin) return false

        return true
      })
    },
  },
  watch: {
    totalRowsPerpage(newValue) {
      console.log(newValue)
    },
  },
  mounted() {
    this.list()
  },
  methods: {
    async downloadTemplate() {
      departaments
        .payment_template_user()
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const filename = 'template'
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = filename
          link.click()
          URL.revokeObjectURL(link.href)
          // } catch (error) {
          //   console.error(error);
          // }
        })
        .catch(error => {
          sweet.ToastMsg('', 'error', 'descarga no completada')
        })
    },
    async submitFile() {
      const formData = new FormData()
      formData.append('file', this.documentCreate.file)
      await departaments.upload_excel_user(formData).then(response => {
        this.documentCreate.file = null
        this.documentCreate = {}
        this.$bvModal.hide('modal-add-document-admin')
        sweet.ToastMsg('', 'success', 'Documento creado')
      })
      // .catch((error) => {
      //   sweet.ToastMsg("", "error", "El archivo no se pudo subir");
      // });
    },
    async list(
      params = {
        params: {
          limit: 5,
          page: 0,
        },
      },
    ) {
      await departaments
        .list(params)
        .then(response => {
          this.items = response.data.data
          this.totalRows = response.data.items
          this.perPage = response.data.limit
          this.currentPage = response.data.page != 0 ? response.data.page : 1
        })
        .catch(error => {
          sweet.ToastMsg('', 'error', 'no se pueden cargar los departamentos')
        })
    },
    show(id) {
      this.$router.push({ name: 'get-departament-admins', params: { id } })
    },
    edit(id) {
      this.editDepartament = this.items.find(item => item.uuid == id)

      this.$bvModal.show('modal-edit-departament')
    },
    delet(id) {
      const { items } = this
      const uuid = id

      sweet.confirmation_delete('este departamento', () => {
        departaments
          .delete(uuid)
          .then(response => {
            const index = items.findIndex(item => item.uuid === uuid)
            if (~index) {
              items.splice(index, 1)
            }
            sweet.ToastMsg('', 'success', 'departamento eliminado')
          })
          .catch(error => {
            console.log(error)
            sweet.ToastMsg('', 'error', 'no se pudo eliminar el departamento')
          })
      })
    },
    uploadFile(event) {
      this.documentCreate.file = event.target.files[0]
    },
    modalClose() {
      if (this.flags.fileUpload == true && this.flags.formUpload == false) {
        documents
          .delete({
            params: {
              document_uuid: `${this.createSales.img}`,
            },
          })
          .then(response => {})
          .catch(error => {
            this.flags.fileUpload = false
            this.createSales.img = ''
          })
      }
    },
    editDepartaments() {
      departaments
        .update(this.editDepartament.uuid, {
          nro: this.editDepartament.nro,
        })
        .then(response => {
          sweet.ToastMsg('', 'success', 'departamento editado')
          this.$bvModal.hide('modal-edit-departament')
        })
        .catch(error => {
          console.log(error)
          sweet.ToastMsg('', 'error', 'no se pudo editar el departamento')
        })
    },
    createDepartaments() {
      departaments
        .create({
          nro: this.createDepartament.nro,
        })
        .then(response => {
          this.items.push(response.data)
          sweet.ToastMsg('', 'success', 'departamento creado')
          this.$bvModal.hide('modal-create-departament')
        })
        .catch(error => {
          console.log(error)
          sweet.ToastMsg('', 'error', 'no se pudo crear el departamento')
        })
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1
      this.currentPage = page
      this.list({
        params: {
          limit: this.totalRowsPerpage,
          page,
        },
      })
    },
    async perPageChanged(perPage) {
      this.totalRowsPerpage = perPage
      this.currentPage = 0
      this.list({
        params: {
          limit: this.totalRowsPerpage,
          page: 0,
        },
      })
    },
  },
}
</script>
